import React, { useContext } from "react";
import { hideToast } from "../store/actions";
import { StateContext } from "../store/context";
import styles from "./toast.module.scss";
export default function Toast() {
  const {
    state: { toast },
    dispatch,
  } = useContext(StateContext);
  if (!toast.show) {
    return null;
  }
  setTimeout(() => hideToast(dispatch), toast.timeout);
  return (
    <div className={`${styles.toast} bg-${toast.state}`}>
      <p className="m-0">{toast.content}</p>
    </div>
  );
}
