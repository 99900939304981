import React, { useContext } from "react";
import axios from "axios";
import { StateContext } from "../store/context";
import {
  hideSpinner,
  showToast,
  showSpinner,
  showModal,
} from "../store/actions";
import MobileNumberInput from "./mobileNumberInput";
import styles from "./socialMediaAdvocate.module.scss";
import ThankyouModal from "./thankyouModal";
export const SocialMediaAdvocate = () => {
  const { dispatch } = useContext(StateContext);

  const backgroundImageDescription =
    "•  Increase your Social equity.•  Invite your network to be the change you wish to see in the world.•  Inspire your network to be part of India's most impactful campaign.•  Be Recognized as a Digital Daana social media advocate.";

  const handleSubmit = async e => {
    e.persist();
    e.preventDefault();
    let json = {};
    new FormData(e.target).forEach((value, key) => {
      json[key] = value;
    });

    const data = json;
    showSpinner(dispatch, "Saving the details ...");
    try {
      axios
        .post(
          "https://sheet.best/api/sheets/4b901759-6cd8-4311-93a8-94f1e85f3628",
          data
        )
        .then(response => response.data)
        .catch(error => {
          throw error;
        });
      hideSpinner(dispatch);
      showModal(dispatch, () => <ThankyouModal />);
    } catch (err) {
      console.error(err, JSON.stringify(err));
      showToast(dispatch, err.message, "danger");
    }
  };
  return (
    <div className=" col-12 row">
      <div className="col-12 col-lg-6">
        <form
          className="form row justify-content-center my-3 col-12 col-lg-12 mx-0"
          id="advocateForm"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div>
              <h3
                className={`font-24 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3 ${styles.title} `}
              >
                you to make social impact with the power of your social media
                connections
              </h3>
              <p
                className={`w-100 px-3 text-align-left  ${styles.subdescription}`}
              >
                Help us with below details
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group mx-0 col-6 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="text"
                id="firstName"
                name="firstName"
                minLength="3"
                required
              ></input>
              <label htmlFor="name">First Name</label>
            </div>

            <div
              className={`form-group mx-0 col-6 col-md-6 mb-4 mb-md-4 pl-2 pl-md-3 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="text"
                id="lastName"
                name="lastName"
                required
                minLength="3"
              ></input>
              <label htmlFor="name">Last Name</label>
            </div>
          </div>
          <div className="row">
            <div
              className={`form-group mx-0 row col-12 col-md-12 mb-4 mb-md-4 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              ></input>
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group mx-0 row col-12 col-md-12 mb-4 mb-md-4 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <MobileNumberInput name="mobileNo" onChangeFunc={false} />
              <label htmlFor="mobileNo"></label>
            </div>
          </div>

          <button
            type="submit"
            className={`btn d-inline-flex align-items-center justify-content-center col-12 py-2 button-8BC540 no-padding-imp text-uppercase ${styles.advocateSubmitBtn}`}
          >
            {" "}
            Submit
          </button>
        </form>
      </div>
      <div className=" col -12 col-lg-6">
        <div
          className={`${styles.infoContainer} d-none d-md-block ${styles.backgroundcolor}`}
        >
          <div>
            <div>
              <div className={styles.backgroundimg}>
                <div className="mt-4">
                  <p
                    className={`font-32 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3 ${styles.advocateHeaderColor} `}
                  >
                    Whats in it for you?
                  </p>
                  <p
                    className={`font-26 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3  ${styles.advocateSubheaderColor}`}
                  >
                    Apart from helping India move forward, as an advocate, you
                    will have the opportunity to:
                  </p>
                  <p className={`font-16  mx-0 col-12 col-md-12 mb-1 mb-md-3`}>
                    {backgroundImageDescription
                      .split(".")
                      .map(function (char, index) {
                        return (
                          <span
                            aria-hidden="true"
                            key={index}
                            className={`font-16 ${styles.advocateBullets}`}
                          >
                            {" "}
                            {char}
                            <br />{" "}
                          </span>
                        );
                      })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SocialMediaAdvocate;
