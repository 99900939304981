export const showToast = (dispatch, content, state) => {
  dispatch({
    type: "TOGGLE_TOAST",
    payload: {
      content,
      state,
      show: true,
    },
  });
};

export const hideToast = dispatch => {
  dispatch({
    type: "TOGGLE_TOAST",
    payload: {
      show: false,
    },
  });
};

export const hideSpinner = dispatch => {
  dispatch({
    type: "TOGGLE_SPINNER",
    payload: {
      show: false,
    },
  });
};

export const showSpinner = (dispatch, content) => {
  dispatch({
    type: "TOGGLE_SPINNER",
    payload: {
      show: true,
      content,
    },
  });
};

export const showModal = (dispatch, Component, onClose, className) => {
  dispatch({
    type: "TOGGLE_MODAL",
    payload: {
      show: true,
      Component,
      onClose,
      className,
    },
  });
};

export const hideModal = dispatch => {
  dispatch({
    type: "TOGGLE_MODAL",
    payload: {
      show: false,
      Component: null,
      onClose: null,
    },
  });
};
