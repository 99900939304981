import React, { useContext } from "react";
import { StateContext } from "../store/context";
import styles from "./spinner.module.scss";
export default function Spinner() {
  const {
    state: { spinner },
  } = useContext(StateContext);
  if (!spinner.show) {
    return null;
  }
  return (
    <div className={styles.spinnerWrapper}>
      <div className={styles.spinner}></div>
      <p className="m-0">{spinner.content}</p>
    </div>
  );
}
