import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const MobileNumberInput = props => {
  const onValueChange = event => {
    if (props.onChangeFunc) {
      props.onchange(event);
    }
  };

  return (
    <PhoneInput
      country={"in"}
      inputProps={{
        name: props.name,
        required: true,
      }}
      autoFormat={true}
      inputClass={"phoneInputField"}
      containerClass={"inputFieldContainer"}
      onChange={phone => onValueChange({ phone })}
    />
  );
};

export default MobileNumberInput;
