import React from "react";

function Picture({ image, className = "", height }) {
  const extraProps = height ? { height } : {};
  return (
    <picture className={className}>
      {image.fluid && (
        <source srcSet={image.fluid.srcSetWebp} type="image/webp" />
      )}
      <img
        src={image.file.url}
        srcSet={image.fluid ? image.fluid.srcSet : ""}
        sizes={image.fluid ? image.fluid.sizes : ""}
        alt={image.title}
        loading="eager"
        {...extraProps}
        className={className}
      />
    </picture>
  );
}

export default Picture;
