import React, { useReducer } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { initialState, StateContext, stateReducer } from "../store/context";
import Footer from "./footer";
import Header from "./header";
import Modal from "./modal";
import Spinner from "./spineer";
import Toast from "./toast";
const queryClient = new QueryClient();

function StateContextTrap({ children }) {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  return (
    <StateContext.Provider value={{ dispatch, state }}>
      {children}
    </StateContext.Provider>
  );
}

export const Layout = ({ children, title }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <StateContextTrap>
        <Header title={title ? title : ""} />
        <main className="container pageTopMargin">
          <div className={`row position-relative`}>{children}</div>
        </main>
        <Footer />
        <Spinner />
        <Toast />
        <Modal />
      </StateContextTrap>
    </QueryClientProvider>
  );
};
