import React, { useContext } from "react";
import { hideModal } from "../store/actions";
import { StateContext } from "../store/context";
import styles from "./modal.module.scss";
export default function Modal() {
  const { state, dispatch } = useContext(StateContext);
  const {
    modal: { show, Component, onClose, className = "" },
  } = state;
  if (!show) {
    return null;
  }
  if (show) {
    document.getElementsByTagName("html")[0].classList.add("overflowYHidden");
  }
  const defaultOnClose = () => {
    hideModal(dispatch);
    onClose && onClose();
    document
      .getElementsByTagName("html")[0]
      .classList.remove("overflowYHidden");
  };
  return (
    <>
      <div
        className={`modal-backdrop static fade show ${styles.backdrop}`}
      ></div>
      <div className={`modal fade show d-block ${styles.modal}`} tabIndex="-1">
        <div
          className={`modal-dialog  position-relative ${className} ${styles.modalDialog} bg-white`}
        >
          <div className={`${styles.modalContent} p-4 `}>
            <button
              type="button"
              className={`${styles.close} btn btn-link btn-lg`}
              aria-label="Close"
              onClick={defaultOnClose}
            >
              <span className="h3 text-secondary" aria-hidden="true">
                &#10005;
              </span>
            </button>
            <Component />
          </div>
        </div>
      </div>
    </>
  );
}
