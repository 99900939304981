import React, { useContext } from "react";
import axios from "axios";
import { StateContext } from "../store/context";
import {
  hideSpinner,
  showToast,
  showSpinner,
  showModal,
} from "../store/actions";
import MobileNumberInput from "./mobileNumberInput";
import styles from "./mentor.module.scss";
import ThankyouModal from "./thankyouModal";
export const Mentor = () => {
  const { dispatch } = useContext(StateContext);
  const backgroundImageDescription =
    "• Be Recognized as a Digital Daana mentor. • Share your wisdom and experiences. • Evolve your own Thinking. • Develop a new relationship. • Deepen your skills as a mentor.";

  const handleSubmit = async e => {
    e.persist();
    e.preventDefault();
    let json = {};
    new FormData(e.target).forEach((value, key) => {
      json[key] = value;
    });
    const data = json;
    showSpinner(dispatch, "Saving the details ...");
    try {
      axios
        .post(
          "https://sheet.best/api/sheets/a82f9e93-a533-4142-8266-263c78de8f57",
          data
        )
        .then(response => response.data)
        .catch(error => {
          throw error;
        });
      hideSpinner(dispatch);
      showModal(dispatch, () => <ThankyouModal />);
    } catch (err) {
      console.error(err, JSON.stringify(err));
      showToast(dispatch, err.message, "danger");
    }
  };
  return (
    <div className=" col-12 row">
      <div className="col-12 col-lg-6">
        <form
          className="form row justify-content-center col-12 col-lg-12 my-3 mx-0"
          id="mentorform"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div>
              <h3
                className={`font-24 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3 ${styles.title} `}
              >
                young people from disadvantaged backgrounds break the inequality
                of opportunity.
              </h3>
              <p
                className={`w-100 px-3 text-align-left  ${styles.subdescription}`}
              >
                Help us with below details
              </p>
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group mx-0 col-6 col-md-6 mb-4 mb-md-4 pr-2 pr-md-3 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="text"
                id="firstName"
                name="firstName"
                minLength="3"
                required
              ></input>
              <label htmlFor="name">First Name</label>
            </div>

            <div
              className={`form-group mx-0 col-6 col-md-6 mb-4 mb-md-4 pl-2 pl-md-3 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="text"
                id="lastName"
                name="lastName"
                required
                minLength="3"
              ></input>
              <label htmlFor="name">Last Name</label>
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group mx-0 row col-12 col-md-12 mb-4 mb-md-4 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <input
                className="form-control"
                type="email"
                id="email"
                name="email"
                placeholder="Email Address"
                required
              ></input>
            </div>
          </div>

          <div className="row">
            <div
              className={`form-group mx-0 row col-12 col-md-12 mb-4 mb-md-4 ${styles.formGroup} ${styles.inputMarginBottom}`}
            >
              <MobileNumberInput name="mobileNo" onChangeFunc={false} />
              <label htmlFor="mobileNo"></label>
            </div>
          </div>

          <button
            type="submit"
            className={`btn d-inline-flex align-items-center justify-content-center col-12 py-2 button-8BC540 no-padding-imp text-uppercase ${styles.mentorSubmitBtn}`}
          >
            {" "}
            Submit
          </button>
        </form>
      </div>
      <div className=" col -12 col-lg-6">
        <div
          className={`${styles.infoContainer} d-none d-md-block ${styles.backgroundcolor}`}
        >
          <div>
            <div>
              <div className={styles.backgroundimg}>
                <div className="mt-4">
                  <p
                    className={`font-32 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3 ${styles.mentorHeaderColor} `}
                  >
                    Whats in it for you?
                  </p>
                  <p
                    className={`font-26 font-weight-bolder mx-0 col-12 col-md-12 mb-1 mb-md-3  ${styles.mentorSubheaderColor}`}
                  >
                    Apart from helping India move forward, as a mentor, you will
                    have the opportunity to:
                  </p>
                  <p className={`font-16  mx-0 col-12 col-md-12 mb-1 mb-md-3`}>
                    {backgroundImageDescription
                      .split(".")
                      .map(function (char, index) {
                        return (
                          <span
                            aria-hidden="true"
                            key={index}
                            className={`font-16 ${styles.mentorBullets}`}
                          >
                            {" "}
                            {char}
                            <br />{" "}
                          </span>
                        );
                      })}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Mentor;
