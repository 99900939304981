import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { showModal } from "../store/actions";
import { StateContext } from "../store/context";
import Mentor from "./mentor";
import SocialMediaAdvocate from "./socialMediaAdvocate";
import styles from "./header.module.scss";

const Header = title => {
  const digitalDaanaTitle = title.title;
  const { dispatch } = useContext(StateContext);
  const className = "customClass";
  const handleClick = e => {
    e.preventDefault();
    showModal(dispatch, () => <Mentor />, "", className);
  };
  const handleAdvocateClick = e => {
    e.preventDefault();
    showModal(dispatch, () => <SocialMediaAdvocate />, "", className);
  };
  const [showButtons, setShowButtons] = useState(false);
  const handleShowButonClick = e => {
    e.preventDefault();
    setShowButtons(true);
  };
  const handleHideButonClick = e => {
    e.preventDefault();
    setShowButtons(false);
  };
  return (
    <nav
      className={`navbar ${styles.navbar} text-black fixed-top navbar-light bg-light px-3 py-1 p-sm-3`}
    >
      <div className="container d-flex justify-content-between align-items-center flex-nowrap px-0">
        <div className="col-6 p-0 col-sm-auto">
          <small className="d-none d-sm-inline pl-2">Powered by</small>
          <Link to="/">
            <img
              src="https://cdn.mettasocial.com/logos/MS_logo_640x180.svg"
              className={styles.poweredByLogo}
              height={30}
              alt="Mettasocial Home page"
            />
          </Link>
        </div>
        {digitalDaanaTitle && (
          <div className="d-none d-md-block">
            <a
              href="#mentorform"
              onClick={e => {
                handleClick(e);
              }}
            >
              <button
                className="btn button-outline-8BC540 font-14 fw-500 mr-3  text-uppercase"
                id="become-mentor-nav"
              >
                Become A Mentor
              </button>
            </a>
            <a
              href="#advocateform"
              onClick={e => {
                handleAdvocateClick(e);
              }}
            >
              <button
                className="btn button-outline-8BC540 font-14 fw-500 mr-3  text-uppercase"
                id="become-mentor-nav"
              >
                Become A Social Media Advocate
              </button>
            </a>
          </div>
        )}
        {digitalDaanaTitle && (
          <div className="d-block d-md-none">
            <a
              href="#showButtons"
              onClick={e => {
                handleShowButonClick(e);
              }}
            >
              <img
                src="/icons/more.svg"
                className={styles.more}
                alt="More icon"
              />
            </a>
          </div>
        )}
      </div>
      <div className={`${styles.bgBlur} ${!showButtons ? "d-none" : ""}`}></div>
      <div
        className={`${styles.formContainer} ${!showButtons ? "d-none" : ""}`}
      >
        <a
          href="#closeFomrButtons"
          onClick={e => {
            handleHideButonClick(e);
          }}
        >
          <img
            src="/icons/close.svg"
            className={styles.closeButton}
            alt="close icon"
          />
        </a>

        <a
          href="#mentorform"
          onClick={e => {
            handleClick(e);
          }}
        >
          <button
            className={`btn button-outline-8BC540  mb-4 font-14 fw-500 text-uppercase w-100 ${styles.formButton}`}
          >
            Become A Mentor
          </button>
        </a>
        <a
          href="#advocateform"
          onClick={e => {
            handleAdvocateClick(e);
          }}
        >
          <button
            className={`btn button-outline-8BC540 fw-500 font-14 text-uppercase w-100 ${styles.formButton}`}
          >
            Become A Social Media Advocate
          </button>
        </a>
      </div>
    </nav>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
