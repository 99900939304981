import React from "react";
export const StateContext = React.createContext();
export const initialState = {
  toast: {
    show: false,
    content:
      "Thank you for your generous donation. You just have enabled one more child for education.",
    state: "info",
    timeout: 5000,
  },
  spinner: {
    show: false,
    content: "Loading...",
  },
  modal: {
    show: false,
  },
  currency: "INR",
  amount: 10000,
};
export function stateReducer(state, action) {
  switch (action.type) {
    case "TOGGLE_TOAST":
      return {
        ...state,
        toast: {
          ...state.toast,
          ...action.payload,
        },
      };
    case "TOGGLE_SPINNER":
      return {
        ...state,
        spinner: {
          ...state.spinner,
          ...action.payload,
        },
      };
    case "TOGGLE_MODAL":
      return {
        ...state,
        modal: {
          ...state.modal,
          ...action.payload,
        },
      };
    case "CURRENCY_CHANGED":
      return {
        ...state,
        currency: action.payload,
      };
    case "AMOUNT_CHANGED":
      return {
        ...state,
        amount: action.payload,
      };
    default:
      throw new Error("Action type is wrong", action.type);
  }
}
