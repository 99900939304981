import React, { useContext } from "react";
import { hideModal } from "../store/actions";
import { StateContext } from "../store/context";
import styles from "./paymentSuccessModal.module.scss";
export default function ThankyouModal() {
  const { dispatch } = useContext(StateContext);

  return (
    <div
      className="text-center"
      style={{ backgroundImage: `url(/images/Clip.png)` }}
    >
      <img
        className={styles.successTickIcon}
        src="/icons/check.svg"
        alt="Success"
      />
      <div
        className={`${styles.thankYouNoteContainer}`}
        id="donation-received-message-modal"
      >
        <h1 className="mb-0 font-36 font-weight-bold">Thank You!</h1>
        <p className="mb-0 font-20">
          for registering your interest in mentoring students.
        </p>
      </div>
      <p className={styles.successSubmessage}>
        The mentoring activity will be starting in July 2021 & we will reach out
        to you with further details.
      </p>
      <button
        className={`btn button-8BC540 ${styles.successClosebUtton} font-16 fw-500 letter-spacing-019 text-uppercase`}
        onClick={() => hideModal(dispatch)}
      >
        Continue
      </button>
      <p className={`${styles.successShareMessage}`}>
        By registering your interest in mentoring you are giving us permission
        to reach out via email and Phone.
      </p>
    </div>
  );
}
