import React from "react";
import LinkedInIcon from "../assets//ic_linkedin.png";
import facebookIcon from "../assets/ic_facebook.svg";
import instagramIcon from "../assets/ic_instagram.svg";
import twitterIcon from "../assets/ic_twitter.svg";
import styles from "./footer.module.scss";

const Footer = () => {
  const year = new Date().getFullYear();
  const followUs = [
    {
      title: "LinkedIn",
      link: "https://www.linkedin.com/company/metta-social/",
      icon: <img src={LinkedInIcon} alt="LinkedIn" />,
    },
    {
      title: "Twitter",
      link: "https://twitter.com/metta_social",
      icon: <img src={twitterIcon} alt="Twitter" />,
    },
    {
      title: "Facebook",
      link: "https://www.facebook.com/Metta-Social-102699761637118/",
      icon: <img src={facebookIcon} alt="Facebook" />,
    },
    {
      title: "Instagram",
      link: "https://www.instagram.com/metta.social/",
      icon: <img src={instagramIcon} alt="Instagram" />,
    },
  ];

  return (
    <footer id="about" className={styles.footer}>
      <div className={`px-4 pt-5 pb-1 container-semi-fluid`}>
        <div className="pb-0 section">
          <div className="px-3 row px-sm-0 justify-content-between">
            <div
              className={`col-12 col-md-5 col-lg-3 mr-lg-5 mb-3 mb-md-0 pt-3 pt-sm-3 pt-md-0  ${styles.footerColumn}`}
            >
              <div className="text-dark">
                <a
                  href="https://www.mettasocial.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className={styles.mettaLogo}
                    src="https://cdn.mettasocial.com/logos/MS_logo_640x180_white.svg"
                    alt="Metta Social Logo"
                  />
                </a>
                <div className={styles.socialMediaIconContainer}>
                  {followUs.map((x, index) => {
                    return (
                      <a
                        href={x.link}
                        className={styles.socialMediaIcon}
                        key={index}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {x.icon}
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
            <div
              className={`col-12 col-md-4 mb-3 mb-md-0 pt-3 pt-sm-3 pt-md-0 mx-lg-3 ${styles.footerColumn}`}
            >
              <strong
                className={`font-16 text-uppercase text-CCCCCC d-inline-block font-weight-bold mb-1`}
              >
                CONTACT US
              </strong>
              <div>
                <a
                  href="mailto: contactus@mettasocial.com"
                  className={styles.footerLink}
                >
                  contactus@mettasocial.com
                </a>
              </div>
              <div>
                <address className="mb-0 text-CCCCCC">
                  Sterling Tower - B Wing, 1st Floor, Pan Card Club Road, Baner,
                  Pune-411045, India.
                </address>
              </div>
            </div>
            <div
              className={`col-12 col-md-3 pt-3 pt-sm-3 pt-md-0  ${styles.footerColumn}`}
            >
              <strong className="font-16 text-uppercase text-CCCCCC d-inline-block font-weight-bold mb-1">
                quick links
              </strong>
              <div className={styles.quickLinksContainer}>
                <div>
                  <a
                    href="https://www.mettasocial.com/terms-and-conditions"
                    className={styles.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Terms Of Use
                  </a>
                </div>
                <span className={styles.horizontalDivider}></span>
                <div>
                  <a
                    href="https://www.mettasocial.com/aml-policy"
                    className={styles.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    AML Policy
                  </a>
                </div>
                <span className={styles.horizontalDivider}></span>
                <div>
                  <a
                    href="https://www.mettasocial.com/privacy-policy"
                    className={styles.footerLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Privacy Policy
                  </a>
                </div>
                <span className={styles.horizontalDivider}></span>
                <div>
                  <a
                    href="https://www.mettasocial.com/use-of-cookies"
                    className={styles.footerLink}
                  >
                    Cookie Policy
                  </a>
                </div>
                <span className={styles.horizontalDivider}></span>
                <div>
                  <a
                    href="https://www.mettasocial.com/about-us/"
                    className={styles.footerLink}
                  >
                    About Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <hr className={`${styles.divider} ${styles.color8A8A8AImp}`} />
          <div
            className={`row align-items-center mb-3 justify-content-center justify-content-md-between ${styles.rightsPaymentsContainer}`}
          >
            <p className="text-center col-12 col-md-5 col-lg-6 font-weight-normal mb-md-0 text-md-left text-8A8A8A">
              &copy;{`${year - 1}-${year}`}&nbsp;All rights reserved&nbsp;
              <a
                href="https://www.mettasocial.com"
                rel="noreferrer noopener"
                target="_blank"
                className={styles.color8A8A8AImp}
              >
                Metta Social
              </a>
            </p>
            <div
              className={`col-12 col-md-7 col-lg-5 font-weight-normal mb-2 mb-md-0 text-center text-md-left text-8A8A8A d-inline-flex align-items-center ${styles.securePaymentInfoContainer}`}
            >
              <p className={`${styles.yourTransactionText} mb-1`}>
                Your transactions are
                <br />
                secure with us
              </p>
              <img
                src="/icons/secure-logo.png"
                className={styles.secureLogo}
                alt="Secured logo"
              />
              <img
                src="/icons/razorpay.png"
                className={styles.paymentGateWaylogo}
                alt="Razorpay payment gateway"
              />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
